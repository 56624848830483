<template>

</template>

<script>
import {msg_err} from "../../utils/ele_component";
import {UserModel} from "../../model/UserModel";
import {OpenModel} from "../../model/OpenModel";
import {CourseModel} from "../../model/CourseModel";

export default {
  name: "zgydDemo",
  data() {
    return {
      coursePath: "zgyd_demo",// 实验路径
      course: {
        webUrl: ""
      }
    }
  },
  beforeCreate() {
    document.title = "中共一大"
  },
  async mounted() {
    await UserModel.loginInPlatformForDemo()
    await this.getCourseInfo()
    // 获取ticket
    let loginResult = await OpenModel.startInWeb(this.course.appId)
    if (loginResult) {
      this.gotoWebUrl(loginResult);
    } else {
      msg_err("获取ticket失败！")
    }
  },
  methods: {
    // 生成网页链接
    gotoWebUrl(result) {
      this.jumpUrl = this.course.webUrl + `?accessToken=${result['access_token']}&username=${result['user_info']['username']}&userDisName=${result['user_info']['realName']}`
      window.location.href = this.jumpUrl;
    },
    // 获取课程详情
    async getCourseInfo() {
      // 获取课程详情
      this.course = await CourseModel.getOneByPath(this.coursePath).catch(res => {
        msg_err("获取课程详情失败！链接地址不正确")
      })
    },
  }
}
</script>

<style scoped>

</style>
